@import './variables';

$arrow-size: 7px;
$arrow-offset: $arrow-size - 1;
$border-radius: 8px;
$tooltip-offset: calc($arrow-size + $border-radius / 2);
$tooltip-arrow-position: min(
  calc(100% - $tooltip-offset),
  max($tooltip-offset, calc(var(--triggerElWidth, 0) / 2 * 1px))
);

@mixin arrow-base {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  display: none;
  pointer-events: none;
}

@mixin arrow-position($side, $transform: none) {
  display: block;
  #{$side}: -($arrow-offset);
  transform: $transform;
}

@mixin arrow-color($placement) {
  @if $placement == top {
    border-color: $pozi_white transparent transparent transparent;
  } @else if $placement == bottom {
    border-color: transparent transparent $pozi_white transparent;
  } @else if $placement == right {
    border-color: transparent $pozi_white transparent transparent;
  } @else if $placement == left {
    border-color: transparent transparent transparent $pozi_white;
  }
}

@mixin arrow-border-width($placement) {
  @if $placement == top {
    border-width: $arrow-size $arrow-size 0 $arrow-size;
  } @else if $placement == bottom {
    border-width: 0 $arrow-size $arrow-size $arrow-size;
  } @else if $placement == right {
    border-width: $arrow-size $arrow-size $arrow-size 0;
  } @else if $placement == left {
    border-width: $arrow-size 0 $arrow-size $arrow-size;
  }
}

.cdk-overlay-pane.cdk-tooltip {
  box-shadow: none;
  filter: drop-shadow(0px 4px 12px rgba(44, 52, 60, 0.24));
  border-radius: $border-radius;
  opacity: 0;

  &.visible {
    transition: opacity 0.2s;
    opacity: 1;
  }

  &::after {
    @include arrow-base;
  }

  // bottom
  &.bottom-start::after {
    @include arrow-position(top, translateX(-50%));
    @include arrow-border-width(bottom);
    @include arrow-color(bottom);
    left: $tooltip-arrow-position;
  }

  &.bottom-end::after {
    @include arrow-position(top, translateX(50%));
    @include arrow-border-width(bottom);
    @include arrow-color(bottom);
    right: $tooltip-arrow-position;
  }

  &.bottom::after {
    @include arrow-position(top, translateX(-50%));
    @include arrow-border-width(bottom);
    @include arrow-color(bottom);
    left: 50%;
  }

  // top
  &.top-start::after {
    @include arrow-position(bottom, translateX(-50%));
    @include arrow-border-width(top);
    @include arrow-color(top);
    left: $tooltip-arrow-position;
  }

  &.top-end::after {
    @include arrow-position(bottom, translateX(50%));
    @include arrow-border-width(top);
    @include arrow-color(top);
    right: $tooltip-arrow-position;
  }

  &.top::after {
    @include arrow-position(bottom, translateX(-50%));
    @include arrow-border-width(top);
    @include arrow-color(top);
    left: 50%;
  }

  // right
  &.right::after {
    @include arrow-position(left, translateY(-50%));
    @include arrow-border-width(right);
    @include arrow-color(right);
    top: 50%;
  }

  &.right-start::after {
    @include arrow-position(left);
    @include arrow-border-width(right);
    @include arrow-color(right);
  }

  &.right-end::after {
    @include arrow-position(left);
    @include arrow-border-width(right);
    @include arrow-color(right);
    bottom: $arrow-offset;
  }

  // left
  &.left::after {
    @include arrow-position(right, translateY(-50%));
    @include arrow-border-width(left);
    @include arrow-color(left);
    top: 50%;
  }

  &.left-start::after {
    @include arrow-position(right);
    @include arrow-border-width(left);
    @include arrow-color(left);
  }

  &.left-end::after {
    @include arrow-position(right);
    @include arrow-border-width(left);
    @include arrow-color(left);
    bottom: $arrow-offset;
  }
}
