// COLORS
$pozi_white: #fefefe;
$pozi_light_gray: #e8eef4;
$pozi_light_gray_svg: invert(91%) sepia(35%) saturate(84%) hue-rotate(177deg)
  brightness(98%) contrast(96%);
$pozi_mid_gray: #b4c8dc;
$pozi_mid_gray_svg: invert(83%) sepia(32%) saturate(204%) hue-rotate(175deg)
  brightness(92%) contrast(86%);
$pozi_dark_gray: #8ca0b4;
$pozi_black: #14181c;
$pozi_black_light: #2c343c;
$pozi_blue: #14b4f0;
$pozi_light_blue: #28c8f0;
$pozi_green: #8cc814;
$pozi_light_green: #a0dc00;
$pozi_orange: #f05014;
$pozi_orange_svg: invert(32%) sepia(55%) saturate(3135%) hue-rotate(358deg)
  brightness(101%) contrast(88%);
$pozi_yellow: #f0c800;
$pozi_yellow_svg: invert(82%) sepia(70%) saturate(4667%) hue-rotate(1deg)
  brightness(93%) contrast(105%);
$pozi_light_yellow: #f0dc14;

$cdk_overlay_box_shadow: 0px 4px 16px 0px #2c343c29;

$white_bg_80: rgba(254, 254, 254, 0.8);

$scroll-fade-gradient-colors:
  rgba(44, 52, 60, 0) 0%,
  $pozi-light_gray 100%;

// SIZE
$header-height: 48px;
$subheader-height: 48px;
$tab-height: 40px;
$table-footer-height: 48px;
$cell-gap: 6px;
$cell-icon-size: 20px;
$list-header-height: 48px;
$tile-size: 96px;
$calendar-chart-size: 136px;
$dashboard-height: calc(
  $tile-size + $list-header-height + $calendar-chart-size
);

// SPACINGS
$spacing-xs: 4px;
$spacing-s: 8px;
