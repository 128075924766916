@use 'inter-ui/default' with (
  $inter-font-display: swap,
  $inter-font-path: '~inter-ui/Inter (web)'
);
@use 'inter-ui/variable' with (
  $inter-font-display: swap,
  $inter-font-path: '~inter-ui/Inter (web)'
);

@include default.all;
@include variable.all;

html,
input,
button,
select {
  font-family: 'Inter', 'system-ui';
}

@supports (font-variation-settings: normal) {
  html,
  input,
  button,
  select {
    font-family: 'Inter var', 'system-ui';
  }
}

@import 'app/styles/variables';
@import 'material-symbols';
@import 'app/styles/tooltip-theme';

html,
body {
  background-color: $pozi_light_gray;
  color: $pozi_black;
  height: 100%;
  font-size: 12px;
  margin: 0;
  font-feature-settings: 'ss01' on;
}

* {
  box-sizing: border-box;
  font-size: 12px;
}

a {
  color: $pozi_black;
  text-decoration: none;
}

.blue {
  color: $pozi_blue !important;
}

.green {
  color: $pozi_green !important;
}

.light-green {
  color: $pozi_light_green !important;
}

.orange {
  color: $pozi_orange !important;
}

.yellow {
  color: $pozi_yellow !important;
}

.mid-gray {
  color: $pozi_mid_gray !important;
}

.dark-gray {
  color: $pozi_dark_gray !important;
}

.black-light {
  color: $pozi_black_light !important;
}

@for $i from 1 through 3 {
  .r-#{$i*90} {
    transform: rotate(#{$i * 90}deg);
  }
}

div.info {
  align-items: center;
  background-color: transparent;
  border: 1px solid $pozi_mid_gray;
  border-radius: 12px;
  color: $pozi_black;
  display: flex;
  flex-direction: row;
  line-height: 20px;
  padding: 9px 12px 9px 8px;

  i[class^='material-symbols'] {
    color: $pozi_dark_gray;
    line-height: 20px;
    margin-right: 10px;
  }

  &.orange {
    background-color: rgba($pozi_orange, 0.2);
    border-color: transparent;

    i[class^='material-symbols'] {
      color: $pozi_orange;
    }
  }
}

div.input {
  align-items: center;
  background-color: $pozi_light_gray;
  border: 0;
  border-radius: 8px;
  display: flex;
  flex-flow: row nowrap;
  font-size: 12px;
  padding: 6px;

  input {
    background-color: transparent;
    border: 0;
    color: $pozi_black;
    flex-basis: 100%;
    font-size: 12px;
    line-height: 16px;
    outline: 0;
    width: 220px;

    & + .error-label {
      color: $pozi_orange;
      font-size: 10px;
      flex-basis: 100%;
    }
  }

  > * {
    color: $pozi_dark_gray;
    flex-basis: content;
    line-height: 20px;
    margin-right: 6px;

    &:last-child {
      margin-right: 0;
    }
  }

  > i {
    color: $pozi_black;
  }

  > i[class^='material-symbols'] {
    font-size: 20px;

    &.error-icon {
      color: $pozi_orange;
      margin-left: auto;
    }
  }
}

button {
  align-items: center;
  border-radius: 8px;
  background-color: $pozi_white;
  display: inline-flex;
  flex-shrink: 0;
  font-size: 12px;
  gap: 6px;
  height: 32px;
  padding: 0 6px 0 6px;
  color: $pozi_black;
  outline: 0;
  box-shadow: none;
  border: none;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    color: $pozi_dark_gray;
  }

  > i[class^='material-symbols-'] {
    font-size: 20px;
  }
}

.subheader {
  background-color: $pozi_white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: $spacing-s;
  padding-right: $spacing-s;

  .tabs {
    gap: $spacing-s;
    display: flex;
    align-items: center;

    .title {
      font-size: 16px;
      padding-left: $spacing-s;
      padding-right: $spacing-s;
    }

    .subtitle {
      font-size: 16px;
    }

    .title,
    .tab {
      font-weight: 600;
    }

    .tab {
      height: $tab-height;
      padding: 13px $spacing-s;

      &.active:after {
        content: '';
        display: block;
        width: 100%;
        height: 4px;
        background-color: $pozi_black_light;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        margin-top: 12px;
      }
    }
  }
}

.cdk-dropdown {
  padding: 5px 9px;
  line-height: 1.4;
}

.cdk-overlay-pane.cdk-tooltip,
.cdk-overlay-pane.cdk-dropdown {
  $check-icon-size: 16px;
  $gap: 6px;

  background-color: $pozi_white;
  border: 0;
  border-radius: 8px;
  color: $pozi_black;
  padding: 5px 9px;
  line-height: 1.4;
  box-shadow: $cdk_overlay_box_shadow;

  .dropdown-content {
    display: flex;
    flex-direction: column;
    text-wrap: nowrap;

    .dropdown-item-group {
      color: $pozi_dark_gray;
      padding: $spacing-xs calc($check-icon-size + $gap);
    }

    .item-list {
      cursor: pointer;
      list-style: none;
      margin: 0;
      padding: 0;

      > li {
        align-items: center;
        display: flex;
        padding: $spacing-xs 20px $spacing-xs 0;
        white-space: nowrap;
        gap: 6px;
      }
    }

    .check-placeholder {
      width: $check-icon-size;
      height: 1px;
      display: block;
      flex-shrink: 0;
    }

    i {
      font-size: $check-icon-size;
    }
  }

  .tooltip {
    &.tooltip-block {
      display: flex;
      flex-direction: column;
      gap: 4px;
      padding: 3px 6px;
    }
  }
}

.cdk-overlay-pane.cdk-dropdown {
  $min-width: 152px;
  max-width: max($min-width, 20vw);
}

.mat-mdc-dialog-surface {
  border-radius: 16px !important;
}

.cdk-overlay-container:has(pozi-comment-editor) {
  .cdk-overlay-custom-backdrop {
    opacity: 0.4;
    background-color: $pozi_black_light;
  }

  .cdk-overlay-connected-position-bounding-box {
    align-items: center;
    justify-content: center;
  }
}

.multiline-snackbar {
  white-space: pre-wrap;
  b {
    font-weight: 600;
  }
}

.material-symbols-rounded {
  font-variation-settings:
    'FILL' 0,
    'wght' 300,
    'GRAD' 0,
    'opsz' 20;
}

b {
  font-weight: 600;
}
